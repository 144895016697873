<template>
	<section class="gardenManagementPage">
		<g-breadcrumb bread="淘系订单录入" />

		<div class="container">
			<div style="display: flex">
				<el-upload ref="upload" action="action"
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" :show-file-list="false"
					:http-request="handlehttpRequest" :on-change="onUploadChange">
					<el-button size="small" type="primary" icon="el-icon-upload">导入淘宝订单</el-button>
				</el-upload>

				<el-button style="margin-left: 20px" size="small" icon="el-icon-download" @click="handleDownload">
					导出淘宝订单模板</el-button>
				<a ref="handleDownload" style="display: none" href="./tradertemplate.xlsx"></a>
				<el-button style="margin-left: 20px" size="small" icon="el-icon-refresh-left" @click="handleClear">更新
				</el-button>
			</div>
			<TableContainer title="录入订单管理">
				<!-- <template slot="button">
					<el-button type="success" @click="addGarden" class="turnBule">添加订单</el-button>
				</template> -->
				<el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
					:cell-style="{ textAlign: 'center' }">
					<!-- <el-table-column prop="appid" label="id" min-width="160" /> -->
					<el-table-column prop="tradeNo" label="订单号" min-width="100" />
					<el-table-column prop="srcTids" label="原始单号" min-width="100" />
					<el-table-column prop="shopName" label="店铺名" min-width="100" />
					<el-table-column prop="created" label="下单时间" min-width="100" />
					<el-table-column prop="receiverArea" label="省市县" min-width="100" />
					<el-table-column prop="paid" label="已付金额" min-width="100" />
					<!-- <el-table-column width="200" label="操作">
						<template slot-scope="scope">
							<el-button type="text" class="reviewed" @click="editGarden(scope.row)">编辑订单</el-button>
							<el-button type="text" class="textOrange" @click="editGarden1(scope.row)">删除订单</el-button>
						</template>
					</el-table-column> -->
				</el-table>
				<GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
					:size.sync="searchForm.size" @pagination="fetchData" />
			</TableContainer>

			<!-- <GDialog :dialog.sync="themeDialog" @btnSure="submitThemeForm1('ref_themeForm')"
				@btnClose="resetThemeForm('ref_themeForm')">
				<el-form ref="ref_themeForm" :model="themeForm" :rules="rules" label-width="100px"
					@submit.native.prevent>
					<el-form-item label="订单号" prop="tradeNo">
						<el-input v-model="themeForm.tradeNo" maxlength="35" placeholder="请输入订单号" />
					</el-form-item>
					<el-form-item label="客户昵称" prop="customerName">
						<el-input v-model="themeForm.customerName" maxlength="35" placeholder="请输入客户昵称" />
					</el-form-item>
					<el-form-item label="地址" prop="receiverAddress">
						<el-input v-model="themeForm.receiverAddress" maxlength="35" placeholder="请输入地址" />
					</el-form-item>
					<el-form-item label="手机号" prop="receiverMobile">
						<el-input v-model="themeForm.receiverMobile" maxlength="35" placeholder="请输入手机号" />
					</el-form-item>
				</el-form>
			</GDialog> -->

			<!-- <GDialog :dialog.sync="themeDialog1" @btnSure="submitThemeForm2('ref_themeForm')"
				@btnClose="resetThemeForm('ref_themeForm')">
				<el-form ref="ref_themeForm" :model="themeForm" :rules="rules" label-width="100px"
					@submit.native.prevent>
					<el-form-item label="订单号" prop="tradeNo">
						<el-input v-model="themeForm.tradeNo" maxlength="35" placeholder="请输入订单号" />
					</el-form-item>
					<el-form-item label="客户昵称" prop="customerName">
						<el-input v-model="themeForm.customerName" maxlength="35" placeholder="请输入客户昵称" />
					</el-form-item>
					<el-form-item label="地址" prop="receiverAddress">
						<el-input v-model="themeForm.receiverAddress" maxlength="35" placeholder="请输入地址" />
					</el-form-item>
					<el-form-item label="手机号" prop="receiverMobile">
						<el-input v-model="themeForm.receiverMobile" maxlength="35" placeholder="请输入手机号" />
					</el-form-item>
				</el-form>
			</GDialog> -->
		</div>
	</section>


</template>

<script>
	import {
		getTbTrade,
		importTbTrade,
		updateTbTrade
	} from '@api/gardenManagement/api_gardenManagement'
	// import { tableData } from './data.js'

	export default {
		data() {
			return {
				file: null,
				tableData: [],
				total: 0,
				searchForm: {
					page: 1,
					size: 10
				},
				themeDialog: {
					title: '编辑订单信息',
					visible: false
				},
				themeDialog1: {
					title: '新增订单信息',
					visible: false
				},
				themeForm: {
					tradeNo: '',
					customerName: '',
					receiverAddress: '',
					receiverMobile: ''
				},
				rules: {
					tradeNo: [{
						required: true,
						message: '请输入订单号',
						trigger: 'blur'
					}],
					customerName: [{
							required: true,
							message: '请输入客户昵称',
							trigger: 'blur'
						},
						// { min: 3, max: 35, message: '请输入用户性别', trigger: 'blur' }
					],
					receiverAddress: [{
							required: true,
							message: '请输入地址',
							trigger: 'blur'
						},
						// { min: 3, max: 11, message: '请输入用户手机号码', trigger: 'blur' }
					],
					receiverMobile: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						// { min: 3, max: 35, message: '请输入用户地址', trigger: 'blur' }
					]
				}

			}
		},
		created() {
			// this.getOptions()
			this.fetchData()
			// this.getDictoryInfoByCode()
		},

		methods: {
			tabHeader() {
				return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
			},
			handlehttpRequest() {},
			fetchData() {
				getTbTrade(this.searchForm).then(res => {
					this.tableData = res.records;
					this.total = res.total
				})
			},
			onUploadChange(file) {
				console.log(file)
				let fileData = new FormData()
				fileData.append('file', file.raw)
				importTbTrade(fileData, 'noLoad').then((res) => {
					this.fetchData()
					//       if(res.code == 20000){
					// 	this.findImportTrade()
					// }
				})
			},
			handleDownload() {
				window.open('http://hfmds.oss-cn-zhangjiakou.aliyuncs.com/tbTrade.xls')
			},
			handleClear() {
				updateTbTrade().then((res) => {
					console.log(res)
					this.fetchData()
					// this.tableData = res
					// this.$router.push('/gardenManagement/wangDianTong')
					// if(res.code == 20000){
					//  this.tableData = res
					//  this.$router.push('/gardenManagement/wangDianTong')
					// }
			
				})
			},
		}
	}
</script>

<style scoped lang="scss">
	body {
		background: #f4f4f4;
		padding: 0;
		margin: 0;
	}

	.container {
		// width: 1024px;
		/* min-height: 100vh; */
		margin: 0 auto;
		// padding: 20px;
		background: #fff;
	}

	.turnBule {
		color: #fff;
		background-color: #58e3e8;
		border-color: rgba(56, 185, 190, 0.12);
	}

	.turnBule:hover,
	.turnBule:focus {
		background: rgba(56, 185, 190, 0.5);
		border-color: rgba(56, 185, 190, 0.5);
		color: #fff;
	}

	.reviewed {
		color: #38b9be;
	}
</style>
